import { slugify, zipCodeToName } from "src/assets/helperFunctions";
import { TCheckedDays, TDateType } from "../../stores/date-store/DateStore";
import { IServerGetEventForm } from "./event-from-server-interface";
import { useWhatsonManager } from "../../stores/whatson-manager-store/WhatsonManagerStore";
import { WhatsOnStore } from "../../stores/WhatsonStore";
import FormValue from "../../types/creators/FormValue";

/**
 *
 * Work in progress
 *
 */

const days: TCheckedDays[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
const dateOccurenceTranslator: { [key: string]: TDateType } = {
  single: "single",
  many: "recurring",
  specific: "various",
  dateRange: "dateRange",
};

export function eventFormToClientContract(
  eventData: IServerGetEventForm | undefined,
  publish: boolean = false
): Partial<WhatsOnStore> {
  if (!eventData) return {};
  const { data, text, images, date, prices, video_links, categories, instagram, place, contactInfo, editSession } =
    eventData;
  const tEnglish = text.english;
  const tFaroese = text.faroese;
  const [startTimeH, startTimeM] = date.dates[0].start_time.split(":");
  const [endTimeH, endTimeM] = date.dates[0].end_time.split(":");
  return {
    // Id
    id: data.id,

    // Description
    enabled: { en: !!text.english.show_it, fo: !!text.faroese.show_it },
    description: {
      en: {
        headlineTextInput: { value: tEnglish.headline, characterCount: tEnglish.headline.length },
        shortSummaryTextInput: { value: tEnglish.excerpt, characterCount: tEnglish.excerpt.length },
        descriptionTextInput: { value: tEnglish.description, characterCount: tEnglish.description.length },
        mapSummaryTextInput: { value: tEnglish.mapSummary, characterCount: tEnglish.mapSummary.length },
      },
      fo: {
        headlineTextInput: { value: tFaroese.headline, characterCount: tFaroese.headline.length },
        shortSummaryTextInput: { value: tFaroese.excerpt, characterCount: tFaroese.excerpt.length },
        descriptionTextInput: { value: tFaroese.description, characterCount: tFaroese.description.length },
        mapSummaryTextInput: { value: tFaroese.mapSummary, characterCount: tFaroese.mapSummary.length },
      },
    },

    // Type
    selectedTypes: Object.fromEntries(
      categories.map((category) => [
        category.id_category,
        {
          id: category.id_category,
          category_en: category.category_en,
          category_fo: category.category_fo,
          slug: slugify(category.category_en),
        },
      ])
    ),

    // Date(s)
    dateType: dateOccurenceTranslator[date.occurence] ?? "single",
    startDate: date.dates[0].start_date ? new Date(date.dates[0].start_date) : null,
    endDate: date.dates[0].end_date ? new Date(date.dates[0].end_date) : null,
    allDay: date.dates[0].start_time === "00:00" && date.dates[0].end_time === "24:00",
    startTime: { hour: startTimeH, minute: startTimeM },
    endTime: { hour: endTimeH, minute: endTimeM },
    dateWhenVisible: new Date(date.when_visible),
    checkedDays: days.filter((day) => date[day]),
    various: date.dates.map((date) => {
      const [startTimeH, startTimeM] = date.start_time.split(":");
      const [endTimeH, endTimeM] = date.end_time.split(":");
      return {
        date: new Date(date.start_date),
        time: { start: { hour: startTimeH, minute: startTimeM }, end: { hour: endTimeH, minute: endTimeM } },
        allDay: date.start_time === "00:00" && date.end_time === "24:00",
      };
    }),

    // Price(s)
    isFree: Boolean(data.is_free),
    prices: prices.map((price) => ({ label: { en: price.en_label, fo: price.fo_label }, price: price.price })),
    bookingUrl: data.pay_url,

    // Media
    media_elements: images.main?.map((image) => ({
      id: image.id_media,
      url: image.medium,
      text: { en: image.english.text, fo: image.faroese.text },
    })),
    instagram_elements: instagram.map((instaObj) => ({
      image: FormValue({
        url: instaObj.medium?.medium ?? "",
        name: "Not in use",
        alt: "Not in use",
        id: instaObj.id_media,
      }),
      lang: { en: instaObj.text.en, fo: instaObj.text.fo },
      name: instaObj.file_name,
      url: FormValue(instaObj.url),
      id: instaObj.id_media?.toString(),
    })),
    include_instagram: Boolean(data.instagram_active),
    instagram: { hashtag: FormValue(data.instagram_hashtag), url: FormValue(data.instagram_profile) },
    optional_media_content: video_links.map((video_link) => video_link.video_link),

    // Location
    location: {
      place: place?.data
        ? {
            id: place.data.id,
            contact_name: place.data.contact_name ?? "",
            contact_zip: place.data.address_zip ?? "",
            contact_email: place.data.contact_email ?? "",
            contact_company: place.data.contact_company ?? "",
            contact_phone: place.data.contact_phone ?? "",
            contact_social: place.data.contact_social ?? "",
            contact_website: place.data.contact_website ?? "",
            headline: place.data.headline ?? "",
          }
        : null,
      address: place.data?.address_name ?? "",
      zip: FormValue(null),
      region: FormValue(null),
      // location: null,
      latitude: null,
      longitude: null,
      custom: false,
      nameOfLocation: "",
    },

    // Organiser
    organizer: {
      comapny_name: FormValue(data.organizer_company),
      name: FormValue(contactInfo.name),
      website: FormValue(contactInfo.website),
      phone: FormValue(contactInfo.phone),
      country_code: FormValue(contactInfo.zip),
      email: FormValue(contactInfo.email),
    },
    organizer_same_as_place: Boolean(data.place_is_host),
    session: {
      sessionId: editSession?.sessionId ?? -1,
      timestamp: editSession?.timestamp ?? -1,
      MAX_AGE: editSession?.MAX_AGE ?? -1,
      userId: editSession?.userId ?? -1,
      userName: editSession?.userName ?? "John Doe",
    },
  };
}

export function SetEventStoreStates(partials: Partial<WhatsOnStore>) {
  const {
    setId,
    setDateSlice,
    setMediaSlice,
    setPriceSlice,
    setTypesSlice,
    setLocationSlice,
    setOrganizerSlice,
    setDescriptionSlice,
    setSessionSlice,
  } = useWhatsonManager.getState().getStore().getState();

  /**
   * Id
   */
  if (partials.id) setId(partials.id);

  /**
   * Session slice
   */
  setSessionSlice({ ...(partials?.session ? { session: partials.session } : {}) });

  /**
   * Description slice
   */
  setDescriptionSlice({
    ...(partials?.description
      ? {
          description: partials.description,
        }
      : {}),
    ...(partials?.enabled
      ? {
          enabled: partials.enabled,
        }
      : {}),
  });

  /**
   * Types slice
   */
  setTypesSlice({
    ...(partials?.selectedTypes
      ? {
          selectedTypes: partials.selectedTypes,
        }
      : {}),
  });

  /**
   * Location slice
   */
  setLocationSlice({
    ...(partials?.location
      ? {
          location: partials.location,
        }
      : {}),
  });

  /**
   * Organizer slice
   */
  setOrganizerSlice({
    ...(partials?.organizer
      ? {
          organizer: partials.organizer,
        }
      : {}),
    organizer_same_as_place: Boolean(partials.organizer_same_as_place),
  });

  /**
   * Date slice
   */
  setDateSlice({
    ...(partials?.dateType
      ? {
          dateType: partials.dateType,
        }
      : {}),
    ...(partials?.startDate
      ? {
          startDate: partials.startDate,
        }
      : {}),
    ...(partials?.endDate
      ? {
          endDate: partials.endDate,
        }
      : {}),
    ...(partials?.allDay
      ? {
          allDay: partials.allDay,
        }
      : {}),
    ...(partials?.startTime
      ? {
          startTime: partials.startTime,
        }
      : {}),
    ...(partials?.endTime
      ? {
          endTime: partials.endTime,
        }
      : {}),
    ...(partials?.dateWhenVisible
      ? {
          dateWhenVisible: partials.dateWhenVisible,
        }
      : {}),
    ...(partials?.checkedDays
      ? {
          checkedDays: partials.checkedDays,
        }
      : {}),
    ...(partials?.various
      ? {
          various: partials.various,
        }
      : {}),
  });

  /**
   * Media slice
   */
  setMediaSlice({
    ...(partials?.media_elements
      ? {
          media_elements: partials.media_elements,
        }
      : {}),
    ...(partials?.instagram_elements
      ? {
          instagram_elements: partials.instagram_elements,
        }
      : {}),
    ...(partials?.include_instagram
      ? {
          include_instagram: partials.include_instagram,
        }
      : {}),
    ...(partials?.instagram
      ? {
          instagram: partials.instagram,
        }
      : {}),
    ...(partials?.optional_media_content
      ? {
          optional_media_content: partials.optional_media_content,
        }
      : {}),
  });

  /**
   * Price slice
   */
  setPriceSlice({
    isFree: Boolean(partials.isFree),
    ...(partials?.prices
      ? {
          prices: partials.prices,
        }
      : {}),
    ...(partials?.bookingUrl
      ? {
          bookingUrl: partials.bookingUrl,
        }
      : {}),
  });
}
