import Layout from "../Layout";
import styles from "./Type.module.scss";
import env from "../../../../../../src/environment.json";
import { ICategory } from "src/views/WhatsOn/stores/type-store/TypesStore";
import { useSafeAxios } from "src/hooks";
import Loading from "src/Components/ui/Loading/Loading";
import SimpleCheckbox from "src/Components/ui/checkbox/SimpleCheckbox/SimpleCheckbox";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect } from "react";
import { getWhatsonStore } from "src/views/WhatsOn/stores/whatson-manager-store/WhatsonManagerStore";

/**
 * Main component
 */
export default function Type() {
  /**
   * Safe axios hook
   */
  const {
    data: categories,
    isLoading,
    error,
  } = useSafeAxios<ICategory[]>(`${env.protocol}${env.env}/api/whatson/events/GetEventCategories`);
  /**
   * Store properties
   */
  const { type, errors, selectedTypes, isTablet, validateTypes, toggleSelectedType } = getWhatsonStore();
  /**
   * Render
   */
  return (
    <Layout
      title="Type of event"
      subtitle={
        <div style={{ display: "flex", alignItems: "start", gap: "10px", height: "fit-content" }}>
          What kind of event are we talking about?
          <br />
          Choose up to three types.
          {/* Only render tooltip on desktop */}
          {!isTablet() && (
            <GlobalToolTipController
              click={isTablet()}
              solid
              title={""}
              className={styles.tooltipIcon}
              priority={"bottom,right,top,left"}
              toolTipElements={
                <div className={"tooltip-card"}>
                  Choose at least one - or at most three - categories. The categories can assist your audience in
                  finding your event.
                </div>
              }
              style={{ marginTop: "2px", height: "fit-content" }}
            >
              <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
            </GlobalToolTipController>
          )}
        </div>
      }
    >
      {/* Types valid */}
      {!errors?.[type]?.Type.valid && (
        <div className={styles.types_invalid_error_message}>At least 1 must be selected</div>
      )}
      {/* Content */}
      <div className={styles.content}>
        {/* Loading */}
        {isLoading && <Loading />}
        {/* Error */}
        {error && <div>Error: {error}</div>}
        {/* Render checkboxes */}
        {!isLoading &&
          !error &&
          categories?.map((category, index) => (
            <Checkbox
              key={category.id}
              index={index}
              disabled={Object.keys(selectedTypes).length >= 3 && !selectedTypes[category.id]}
              labels={{ en: category.category_en, fo: category.category_fo }}
              onChange={() => {
                toggleSelectedType(category);
                if (!errors?.[type]?.Type.valid) validateTypes();
              }}
              checked={!!selectedTypes[category.id]}
            />
          ))}
      </div>
    </Layout>
  );
}

/**
 * Checkbox component
 */
function Checkbox({
  labels,
  index,
  onChange,
  checked,
  className = "",
  disabled = false,
}: {
  labels: { en: string; fo: string };
  index: number;
  onChange: () => void;
  checked: boolean;
  className?: string;
  disabled?: boolean;
}) {
  const checkBoxId = `checkbox_${index}`;
  return (
    <div
      className={`${styles.container} ${styles.TypeCheckboxContainer} ${className} ${disabled ? styles.disabled : ""}`}
    >
      <SimpleCheckbox id={checkBoxId} checked={checked} onChange={onChange} label={labels.en} />
      <div className={styles.secondaryLabel}>{labels.fo}</div>
    </div>
  );
}
