import { StateCreator } from "zustand";
import { ValidatePrice } from "./utils";
import { useWhatsonManager } from "../whatson-manager-store/WhatsonManagerStore";

/**
 * Price Interface
 */
export interface IPrice {
  price: string;
  label: {
    en: string;
    fo: string;
  };
}
/**
 * Price Slice Interface
 */
export interface PriceSlice {
  isFree: boolean;
  prices: IPrice[];
  bookingUrl: string;

  /**
   * setPriceSlice - Full setter
   *
   * @param partials
   *
   * @example
   * setPriceSlice({ isFree: true, prices: [{ price: "100", label: { en: "Price", fo: "Prís" } }], bookingUrl: "https://booking.com" })
   *
   * @returns void
   */
  setPriceSlice: (partials: Partial<PriceSlice>) => void;

  /**
   * Set the isFree state
   *
   * @param isFree
   *
   * @returns void
   */
  setIsFree: (isFree: boolean) => void;
  /**
   * Add a price object
   */
  addPriceObj: () => void;
  /**
   * Update a price object
   *
   * @param index
   * @param newPrice
   *
   * @returns void
   */
  updatePriceObj: (index: number, newPrice: IPrice) => void;
  /**
   * Remove a price object
   *
   * @param index
   *
   * @returns void
   */
  removePriceObj: (index: number) => void;
  /**
   * Set the booking url
   *
   * @param bookingUrl
   *
   * @returns void
   */
  setBookingUrl: (bookingUrl: string) => void;
  /**
   * Validate the price slice
   */
  validatePrice: () => boolean;
  /**
   * Reset the price slice
   */
  resetPriceSlice: () => void;
}
/**
 * Default Price
 */
const defaultPrice: IPrice = {
  price: "",
  label: {
    en: "",
    fo: "",
  },
};

const INITIAL_STATES = () => ({
  isFree: true,
  prices: [defaultPrice],
  bookingUrl: "",
});
/**
 * Create a price slice
 */
export const createPriceSlice: StateCreator<PriceSlice> = (set, get) => ({
  ...INITIAL_STATES(),

  /**
   * Setters / Actions
   */

  setPriceSlice: (partials) => set({ ...partials }),

  setIsFree: (isFree: boolean) => {
    set({ isFree });
  },
  addPriceObj: () => {
    set({
      prices: [...get().prices, defaultPrice],
    });
  },
  updatePriceObj: (index, newPrice) => {
    const updatedPrices = get().prices.map((item, idx) => (idx === index ? { ...newPrice } : item));
    set({ prices: updatedPrices });
  },
  removePriceObj: (index) => {
    const filteredPrices = get().prices.filter((_, idx) => idx !== index);
    set({ prices: filteredPrices });
  },
  setBookingUrl: (bookingUrl: string) => {
    set({ bookingUrl });
  },
  validatePrice: () => {
    const state = get();
    const whatson_store = useWhatsonManager.getState().getStore();
    const { valid, prices, is_free: isFree } = ValidatePrice(state.prices, state.isFree);
    const { setError } = whatson_store.getState();
    setError("Price(s)", valid);
    set({
      prices,
      isFree,
    });
    return valid;
  },
  resetPriceSlice: () => {
    set({
      ...INITIAL_STATES(),
    });
  },
});
