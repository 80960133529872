import { Component } from "react";
import env from "../environment.json";
import "./Main.scss";
import Navigation from "./Navigation/Navigation";
import Sidebar from "./Sidebar/Sidebar";
import Content from "./Content/Content";
import Filter from "./Filter/filter";
import Login from "./Login/Login";
import languages from "../language.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalObject from "../assets/globalVariables";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { withAllOfStore } from "./ZustandStores/allOfStore";
import { storeNotificationToken } from "src/notifications/storeNotificationToken";
import { modulesConfig } from "src/utils";
import PrivacyPolicy from "./Modals/PrivacyPolicy";

const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarCollapsed: false,
      // sidebarShow: "list",
      sidebarShowAllParam: true,
      contentShow: props.store.route > 0 ? props.store.route[0].nav : "",
      currentSite: undefined,
      superUser: false,
      searchValue: "",
      checkboxFilter: { filterFor: "", filter: ["all"] },
      panel: "profile",
      newNav: "",
      filterdata: {
        style: undefined,
        listToMapName: "",
        listToMap: undefined,
        startDate: undefined,
        endDate: undefined,
      },
      addFilterDroppedDown: [false, ""], //Is filter open, and what filter to open eg activity
      selectedNavMenu: "",
      language: "english",
      activityUpdate: "0",
      confirmBeforeNavigate: "",
      createNew: "",
      idleLoggedOutAfterLoggedIn: false,
      notificationAmounts: {
        activity: 0,
      },
      infoscreenMenu: "articles",
      store: props.store,
    };

    this.axios = require("axios");
    this.listOptions = [];
    this.sites = [];
    this.myInfo = { localImg: "", localImg_extension: "" };
    this.timeout = null;

    this.collapseSidebar = this.collapseSidebar.bind(this);
    this.showInSidebar = this.showInSidebar.bind(this);
    this.showInContent = this.showInContent.bind(this);
    this.siteSelect = this.siteSelect.bind(this);
    this.optionClicked = this.optionClicked.bind(this);
    this.SetState = this.SetState.bind(this);
    this.openCloseFilter = this.openCloseFilter.bind(this);
    this.setConfirmBeforeNavigate = this.setConfirmBeforeNavigate.bind(this);
    this.setInfoscreenMenu = this.setInfoscreenMenu.bind(this);
  }

  collapseSidebar(bool) {
    if (typeof bool === "boolean") {
      this.setState({ sidebarCollapsed: bool });
    }
  }

  showInSidebar(show, showAllParameter = true) {
    if (typeof show === "string" || show instanceof String) {
      this.setState({
        sidebarShow: show,
        sidebarShowAllParam: !!showAllParameter,
      });
    }
  }

  showInContent(show) {
    if (typeof show === "string" || show instanceof String) {
      this.setState({ contentShow: show });
    }
  }

  getSessionId() {
    return this.axios.post(env.protocol + env.env + "/api/public/checkLogin.php").then((response) => {
      return response.data;
    });
  }

  siteSelect(i) {
    this.setState({ currentSite: i });
  }

  openCloseFilter(openClose, filterContent) {
    this.setState({
      addFilterDroppedDown: openClose, //Opens right filter
      filterdata: filterContent,
    });
  }

  componentDidMount() {
    const store = this.props.store;

    // const sitesStore = useSitesStore();
    // useEffect(()=>{
    //   const unsubscribe = store.subscribe(newState=>newState.)
    // },[]);
    // sitesStore.getAllSites();

    // Open a loader overlay
    const loaderOverlayProperties = store.overlayStore.addOverlay("spinner", (close) => {
      return <></>;
    });

    // console.log("OLD", store.sitesStore);
    store.userStore
      .getLoggedInUser()
      .then(() => {
        store.sitesStore.getAllSites();
        store.userStore.getUsers();
        store.routerStore.initRoute(store.permCheckStore.permCheckFunc);
        store.userStore.getRoles();
        store.whatsonStore.getAllWhatson();
        store.whatsonStore.getTourOperators();
        store.articlesStore.getArticleTypes();
      })
      .finally(() => {
        // Close loader overlay
        loaderOverlayProperties.close();
      });

    var userId = "";

    userId = sessionStorage.getItem("vfiUser");

    this.axios
      .post(env.protocol + env.env + "/api/public/GetSites.php", {
        userId,
        allElement: true,
        clickedNav: sessionStorage.getItem("selectedNavMenu"),
      })
      .then((response) => {
        response.data = response.data !== "" ? response.data : [];
        response.data.forEach((element) => {
          this.listOptions.push({
            index: element.siteId,
            name: element.name,
            order: element.order,
            permission: element.permission,
            rootPage: element.rootPage ?? 0,
          });
        });
        if (this.listOptions.length > 0) {
          this.optionClicked(this.listOptions[0]);
        }
        this.setState({ sitesChecked: true });
      })
      .catch((error) => {
        this.setState({
          treeChecked: true,
          error: true,
          errorMessage: error.message,
        });
      });
    if (this.listOptions.length > 0) {
      // this.optionClicked(this.listOptions[0]);
    }
    this.setState({ sitesChecked: true });

    //Check activity updates
    this.axios
      .post(env.protocol + env.env + "/api/secured/getActivityUpdates", {
        userId,
      })
      .then((response) => {
        // this.setState({ activityUpdate: response.data.Counting })
        this.setState({
          notificationAmounts: {
            ...this.state.notificationAmounts,
            activity: response.data.Counting,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });

    this.axios
      .post(env.protocol + env.env + "/api/secured/GetUserInfo", {
        vfiUser: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        this.myInfo = response.data;
        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });

    window.onload = this.restartAutoReset;
    document.onmousemove = this.restartAutoReset;
    document.onkeydown = this.restartAutoReset;

    // if (window.location.hash === "") {
    //   sessionStorage.setItem("selectedNavMenu", "Home");
    //   window.location.hash = "home";
    //   this.setState({ contentShow: "Home" });
    // }

    // if (store.route < 1) {
    //   sessionStorage.setItem("selectedNavMenu", "Home");
    // //   window.location.hash = "home";
    //   this.setState({ contentShow: "Home" });
    //   store.setRouter({ route: ['home'] });
    // } else {
    //   this.setState({ contentShow: store.route[0] });
    // }

    // this.setState({ contentShow: store.route[0] });

    if (modulesConfig.notifications.enabled) {
      storeNotificationToken();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const store = this.props.store;

    if (this.state.sidebarShowAllParam !== prevState.sidebarShowAllParam) {
      if (this.listOptions[0].index === "0" && this.listOptions[1]) this.optionClicked(this.listOptions[1]);
    }
  }

  restartAutoReset = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      // Insert your code to reset you app here
      this.setState({ idleLoggedOutAfterLoggedIn: true });
    }, 1000 * 1200); // 20 Minutes
  };

  updateNewNav = (i) => {
    this.setState({ newNav: i });
  };

  optionClicked(e) {
    this.listOptions.forEach((element, i) => {
      this.listOptions[i].selected = false;

      if (element.index === e.index) {
        this.listOptions[i].selected = true;
      }
    });

    if (e.index === "0") {
      this.setState({ allSelected: true });
    } else {
      this.setState({ allSelected: false });
    }

    this.siteSelect(e.index);
  }

  SetState(pathToChange, value) {
    if (pathToChange.split(".").length > 1) {
      let attr1 = pathToChange.split(".")[0];
      let attr2 = pathToChange.split(".")[1];
      this.setState({
        [attr1]: {
          ...this.state[attr1],
          [attr2]: value,
        },
      });
    } else {
      this.setState({ [pathToChange]: value });
    }
  }

  updateActUpt = (countings) => {
    this.setState({ activityUpdate: countings });
  };

  navigateWhatson = (whereTo, sidebarShow, createNewWhat) => {
    this.axios
      .post(env.protocol + env.env + "/api/public/GetWhatsonSites.php", {
        //Problems with site, so we need to update site here
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        this.whatsonList = response.data !== "" ? response.data : [];
        if (Array.isArray(this.whatsonList)) {
          var indexNav = sidebarShow - 1;
          var e = {
            ...this.whatsonList[indexNav],
            index: this.whatsonList[indexNav].id,
          };
          // this.optionClickedWhatson(e);
          this.siteSelect(e);
          sessionStorage.setItem("whatson_site_name", e.name);

          //Redirect
          window.location.hash = "/" + whereTo.toLowerCase();
          this.showInSidebar(whereTo.toLowerCase());
          this.collapseSidebar(false);
          this.showInContent(whereTo.toLowerCase());
          this.updateNewNav("5");
          this.setState({ createNew: createNewWhat });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  navigateUser = (createNew) => {
    this.collapseSidebar(false);
    this.showInSidebar("list");
    this.showInContent("users");
    this.setState({
      newNav: 6,
      createNew: createNew,
    });
    this.optionClicked(this.listOptions[0]);
    window.location.hash = "/users";
  };

  navigatePages = (createNew) => {
    this.showInContent("pages");
    this.setState({
      newNav: 2,
      createNew: createNew,
    });
    window.location.hash = "/pages";
  };

  navigateActivity = () => {
    this.updateNewNav("1");
    this.showInContent("activity");
    window.location.hash = "/activity";
  };

  // Navigate to certain event, tour etc
  // navigateTo = (element, whatson, newnav, show, id) => {
  //   window.location.hash = "/whatson"
  //   this.setState({
  //     newNav: newnav,
  //     contentShow: show,
  //     nav: id,
  //     sidebarShow: show,
  //     sidebarCollapsed: false
  //   },
  //     () => this.ContentDisplayElement.current.contentEditContent(whatson, element));
  // }

  navigateToUser = (type) => {
    window.location.hash = "/users/" + this.myInfo.userId;
    switch (type) {
      case "profile":
        this.SetState("panel", "profile");
        break;
      case "activity":
        this.SetState("panel", "activity");
        break;
      case "permission":
        this.SetState("panel", "permission");
        break;
      case "settings":
        this.SetState("panel", "settings");
        break;
      default:
        return this.SetState("panel", "profile");
    }
  };

  // navigate = (whatNavigation = "") => {
  //   whatNavigation = whatNavigation.toLowerCase();

  // }

  setConfirmBeforeNavigate(message) {
    this.setState({ confirmBeforeNavigate: message });
  }

  setInfoscreenMenu(id) {
    this.setState({ infoscreenMenu: id });
  }

  render() {
    const previewUserId = sessionStorage.getItem("preview_user_id");

    return (
      <div className={previewUserId ? "main previewUserId" : "main"}>
        <PrivacyPolicy />
        <Navigation
          // key={Object.values(this.state.notificationAmounts).join(",")}
          collapseSidebar={this.collapseSidebar}
          sidebarShow={this.showInSidebar}
          contentShow={this.showInContent}
          optionClicked={this.optionClicked}
          sites={this.listOptions}
          logout={this.props.logout}
          newNav={this.state.newNav}
          updateNewNav={this.updateNewNav}
          activityUpdate={this.state.activityUpdate}
          navigateToUser={this.navigateToUser}
          navigateToSettings={this.navigateToSettings}
          myInfo={this.myInfo}
          confirmBeforeNavigate={this.state.confirmBeforeNavigate}
          notificationAmounts={this.state.notificationAmounts}
          store={this.props.store}
        />
        {globalObject.vfiUser && (
          <Sidebar
            collapsed={this.state.sidebarCollapsed}
            show={this.state.sidebarShow}
            showAllParameter={this.state.sidebarShowAllParam}
            siteSelect={this.siteSelect}
            contentshow={this.state.contentShow}
            listOptions={this.listOptions.filter((e) => {
              if (this.state.sidebarShowAllParam === false && e.index === "0") {
                return false;
              }

              return true;
            })}
            optionClicked={this.optionClicked}
            infoscreenMenu={this.state.infoscreenMenu}
            setInfoscreenMenu={this.setInfoscreenMenu}
            store={this.props.store}
          />
        )}
        <Content
          show={this.state.contentShow}
          site={this.state.currentSite}
          listOptions={this.listOptions}
          SetState={this.SetState}
          checkboxFiltering={this.checkboxFiltering}
          panel={this.state.panel}
          endDate={this.state.filterdata.endDate}
          startDate={this.state.filterdata.startDate}
          addFilterDroppedDown={this.state.addFilterDroppedDown}
          openCloseFilter={this.openCloseFilter}
          language={this.state.language}
          updateNewNav={this.updateNewNav}
          showInContent={this.showInContent}
          showInSidebar={this.showInSidebar}
          collapseSidebar={this.collapseSidebar}
          nav={this.state.nav}
          setNav={this.setNav}
          activityUpdate={this.state.activityUpdate}
          updateActUpt={this.updateActUpt}
          createNew={this.state.createNew}
          setConfirmBeforeNavigate={this.setConfirmBeforeNavigate}
          navigateWhatson={this.navigateWhatson}
          navigateUser={this.navigateUser}
          navigatePages={this.navigatePages}
          navigateActivity={this.navigateActivity}
          notificationAmounts={this.state.notificationAmounts}
          infoscreenMenu={this.state.infoscreenMenu}
          store={this.props.store}
        />
        <Filter
          contentShow={this.state.contentShow}
          currentSite={this.state.currentSite}
          SetState={this.SetState}
          overviewClick={this.overviewClick}
          searchValue={this.state.searchValue}
          checkboxFilter={this.state.checkboxFilter} //To filter list
          filterdata={this.state.filterdata}
          panel={this.state.panel}
          addFilterDroppedDown={this.state.addFilterDroppedDown}
          openCloseFilter={this.openCloseFilter}
        />
        {this.state.idleLoggedOutAfterLoggedIn && (
          <div className="login-overlay">
            <div className="login-section">
              <Login
                topMessage={languages[language].content.logged_out}
                loginSuccess={() => {
                  this.setState({ idleLoggedOutAfterLoggedIn: false });
                }}
              />
            </div>
          </div>
        )}
        {previewUserId && (
          <div className="end-user-preview">
            <FontAwesomeIcon
              className="close"
              icon={faTimes}
              onClick={() => {
                sessionStorage.removeItem("preview_user_id");
                sessionStorage.removeItem("preview_user_profession");
                this.setState({ lastUpdate: new Date().getTime() });
              }}
            />
            <div className="text">User preview</div>
          </div>
        )}
      </div>
    );
  }
}

export default withAllOfStore(Main);
// export default Main;
